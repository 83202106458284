export const rt3 = ﻿[["כה\"ג ","in such a case/manner\nכִּי הַאי גַוְונָא ","because through these means\nכִּי הַאי גַוְונָא","in such a case/manner\nכִּי הָכִי גַוְונָא","because through these means\nכִּי הָכִי גַוְונָא"],
["כ\"ע ","the whole world\nכּוּלֵי עָלְמָא","forever and ever\nכּוּלֵי עָלְמָא","the whole world\nכֹּל עוֹלָם","forever and ever\nכֹּל עוֹלָם"],
["כ\"ש / כש\"כ ","certainly/all the more so\nכָּל שְׁכֵּן","everything that is true/logical\nכָּל שְׁכֵּן","certainly/all the more so\nכִּי שְׁכֵּן","everything that is true/logical\nכִּי שְׁכֵּן"],
["ל\"ל","why do I need\nלָמָּה לִי","why should I learn it this way\nלָמָּה לִי","why do I need\nלֵימָא לִי","why should I learn it this way\nלֵימָא לִי"],
["מ\"ד ","the opinion\nמַאן דְּאָמַר","who said this\nמַאן דְּאָמַר","the opinion\nמִי דְּאָמַר","who said this\nמִי דְּאָמַר"],
["מ\"ט ","what is the reason\nמַאי טַעְמָא","what is the answer\nמַאי טַעְמָא","what is the reason\nמַה טַעַם","what is the answer\nמַה טַעַם"],
["מ\"מ ","nevertheless/in all cases\nמִכָּל מָקוֹם","from every place/location\nמִכָּל מָקוֹם","what is his opinion\nמַאי מַאן","who is the author\nמַאי מַאן"],
["מנה\"מ ","from where do we know these words\nמְנָא הָנִי מִילִּי ","when do we say these words\nמְנָא הָנִי מִילִּי","from where do we know these words\nמִנַּיִן הָנִי מִילִּי","when do we say these words\nמִנַּיִן הָנִי מִילִּי"],
["מנ\"ל ","from where do we know\nמְנָא לָן","from where do we know these words\nמְנָא לָן","from where do we know\nמִנַּיִן לָן","from where do we know these words\nמִנַּיִן לָן"],
["מ\"ש ","what’s the difference\nמַאי שְׁנָא","what's the teaching\nמַאי שְׁנָא","what’s the difference\nמַאי שָׁנִּי","what’s the teaching\nמַאי שָׁנִּי"],
["נ\"מ ","practical difference\nנָפְקָא מִינָּהּ","let us derive a teaching from it\nנָפְקָא מִינָּהּ","practical difference\nנָפְקָא מִינֵּיהּ","let us derive a teaching from it\nנָפְקָא מִינֵּיהּ"],
["ס\"ד ","you would think\nסַלְקָא דַעְתָּךְ","did you really think\nסַלְקָא דַעְתָּךְ","you would think\nסָבַר דַעְתָּךְ","did you really think\nסָבַר דַעְתָּךְ"],
["סד\"א ","you would think I would say\nסַלְקָא דַעְתָּךְ אֲמִינָא","you would think you could say\nסַלְקָא דַעְתָּךְ אֲמִינָא","you would think I could say\nסָבַר דַעְתָּךְ אֲמִינָא","you would think you could say\nסָבַר דַעְתָּךְ אֲמִינָא"],
["ס\"ל ","he's of the opinion\nסְבִירָא לֵיהּ","he thought\nסְבִירָא לֵיהּ","he’s of the opinion\nסְבִירָא לַה","he thought\nסְבִירָא לַה"]];
