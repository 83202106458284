import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import './index.css';
import aishLogo from './aishlogo.png';
import {vocabArray} from './vocab.js';
import {easyHardCodedQuizArray} from './easy_hard_coded_quiz.js';
import {mediumHardCodedQuizArray} from './medium_hard_coded_quiz.js';
import {hardHardCodedQuizArray} from './hard_hard_coded_quiz.js';
import {fp1} from './fp1.js';
import {fp2} from './fp2.js';
import {fp3} from './fp3.js';
import {fp4} from './fp4.js';
import {rt1} from './rt1.js';
import {rt2} from './rt2.js';
import {rt3} from './rt3.js';
import {rt4} from './rt4.js';
import {easy1} from './easy1.js';
import {easy2} from './easy2.js';
import {easy3} from './easy3.js';
import {easy4} from './easy4.js';
import {easy5} from './easy5.js';
import {med1} from './med1.js';
import {med2} from './med2.js';
import {med3} from './med3.js';
import {med4} from './med4.js';
import {med5} from './med5.js';
import {hard1} from './hard1.js';
import {hard2} from './hard2.js';
import {hard3} from './hard3.js';
import {hard4} from './hard4.js';
import {prefixes} from './prefixes.js';
import {gematria} from './gematria.js';
import {possessivePronouns} from './possessivePronouns.js';
import {easy1_Cards} from './easy1_Cards.js';
import {easy2_Cards} from './easy2_Cards.js';
import {easy3_Cards} from './easy3_Cards.js';
import {easy4_Cards} from './easy4_Cards.js';
import {easy5_Cards} from './easy5_Cards.js';
import {med1_Cards} from './med1_Cards.js';
import {med2_Cards} from './med2_Cards.js';
import {med3_Cards} from './med3_Cards.js';
import {med4_Cards} from './med4_Cards.js';
import {med5_Cards} from './med5_Cards.js';
import {hard1_Cards} from './hard1_Cards.js';
import {hard2_Cards} from './hard2_Cards.js';
import {hard3_Cards} from './hard3_Cards.js';
import {hard4_Cards} from './hard4_Cards.js';
import {fp1_Cards} from './fp1_Cards.js';
import {fp2_Cards} from './fp2_Cards.js';
import {fp3_Cards} from './fp3_Cards.js';
import {fp4_Cards} from './fp4_Cards.js';
import {rt1_Cards} from './rt1_Cards.js';
import {rt2_Cards} from './rt2_Cards.js';
import {rt3_Cards} from './rt3_Cards.js';
import {rt4_Cards} from './rt4_Cards.js';
import {prefixes_Cards} from './prefixes_Cards.js';
import {gematria_Cards} from './gematria_Cards.js';
import {possessivePronouns_Cards} from './possessivePronouns_Cards.js';
import WebFont from 'webfontloader';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, child, get, update, connectDatabaseEmulator } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendEmailVerification , updateProfile } from "firebase/auth";

WebFont.load({
    custom: {
        families: ['Rock Salt'],
    },
});

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4RKN2lsdBOig-vOTXn88-IxmstEmxqlw",
  authDomain: "aishgemaracard.com",
  databaseURL: "https://aish-gemara-card-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "aish-gemara-card",
  storageBucket: "aish-gemara-card.appspot.com",
  messagingSenderId: "592885602918",
  appId: "1:592885602918:web:fb64955c168c59b5a16ba7",
  measurementId: "G-MCZJ1JTRY8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);
const auth = getAuth(app);
// connectDatabaseEmulator(db, "localhost", 9000);
// connectAuthEmulator(auth, "http://localhost:9099");

onAuthStateChanged(auth, (user) => {
  if (user && user.emailVerified) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    ReactDOM.render(
	  	<UI loggedIn={true}/>,
	  	document.getElementById('root')
	  );
    // ...
  } else {
    // User is signed out
    // ...
    ReactDOM.render(
	  	<UI loggedIn={false} />,
	  	document.getElementById('root')
	    );
  }
});

//sign-up form
class SignUpForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			form: 'sign in',
		};
	}

	handleSubmit = async(e) => {
		e.preventDefault();

		if (this.state.form === 'sign up'){

            let name = e.target.firstName.value + " " + e.target.lastName.value;
            this.props.updateEmail(e.target.email.value);
            const experience = e.target.experience.options[e.target.experience.selectedIndex].value;
			await createUserWithEmailAndPassword(auth, e.target.email.value, e.target.password.value, experience, name)
                .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                const scores = new Array(31).fill(0);

                set(ref(db, 'users/' + user.uid), {
                    firstName: e.target.firstName.value,
                    lastName: e.target.lastName.value,
                    email: e.target.email.value,
                    experience: experience,
                });

                set(ref(db, 'scores/' + user.uid), {
                    scores: scores,
                });

                updateProfile(userCredential.user, {
                    displayName: name,
                });

                this.props.pageChange('verify');
                // ...
            })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("Error code: " + errorCode + ", " + errorMessage);
                    // ..
                });
		} else {
			await signInWithEmailAndPassword(auth, e.target.email.value, e.target.password.value)
			  .then((userCredential) => {
			    // Signed in
			    const user = userCredential.user;
                if(user.emailVerified){
                    this.props.pageChange('flashcards');
                } else {
                    this.props.pageChange('verify');
                }
			    // ...
			  })
			  .catch((error) => {
			    const errorCode = error.code;
			    const errorMessage = error.message;
			  });
		}

	};

	changeForm() {
		if(this.state.form === 'sign in'){
			this.setState({
				form: 'sign up',
			});
		} else {
			this.setState({
				form: 'sign in',
			})
		}
	}

	render() {
        if (this.state.form === 'sign in') {
            return (
    			<div>
                    <Helmet>
                        <title>Aish Gemara Card</title>
                    </Helmet>
    				<h1 id='title'>Aish Gemara Card</h1>
    				<form onSubmit={this.handleSubmit}>
    					<h1>Sign In</h1>
    					<label htmlFor='email'>Email</label>
    					<input type='email' name='email' placeholder='Email' />
    					<label htmlFor="password">Password</label>
    			        <input type="password" name="password" placeholder="Password" />
    			        <button type="submit">Sign In</button>
    					<p>
    						Don't have an account yet?&nbsp;
    						<button id="hyperButton" onClick={() => this.changeForm()}>Sign up.</button>
    					</p>
    				</form>
    			</div>
    		);
        } else {
            return (
    			<div>
    				<h1 id='title'>Aish Gemara Card</h1>
    				<form onSubmit={this.handleSubmit}>
    					<h1>Sign Up</h1>
                        <label htmlFor='firstName'>First Name</label>
    					<input type='firstName' name='firstName' placeholder='First Name' />
                        <label htmlFor='lastName'>Last Name</label>
    					<input type='lastName' name='lastName' placeholder='Last Name' />
                        <label htmlFor="experience">Experience Learning Gemara:&nbsp;</label>
                        <select name="experience" id="experience">
                            <option value="<1 year">Less than 1 year</option>
                            <option value="1 to 5 years">1-5 years</option>
                            <option value=">5 years">More than 5 years</option>
                        </select>
    					<label htmlFor='email'>Email</label>
    					<input type='email' name='email' placeholder='Email' />
    					<label htmlFor="password">Password</label>
    			        <input type="password" name="password" placeholder="Password" />
    			        <button type="submit">Create Account</button>
    					<p>
    						Already have an account?&nbsp;
    						<button id="hyperButton" onClick={() => this.changeForm()}>Sign in.</button>
    					</p>
    				</form>
    			</div>
    		);
        }
	}

}

class Verify extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            auth: getAuth(),
            verificationSent: false,
        }
    }


    componentDidMount() {
        this.interval = setInterval(() => this.setState({
            time: Date.now(),
            auth: getAuth(),
        }), 1000);
    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }


    sendVerificationLink() {

        sendEmailVerification(this.state.auth.currentUser)
            .then(() => {
                // Email verification sent!
                this.setState({
                    verificationSent: true,
                });
            });
    }

    handleLogOut() {
        const auth = getAuth();

        signOut(auth).then(() => {
          // Sign-out successful.
          this.props.signOut();
        }).catch((error) => {
          // An error happened.
        });
    }

    render() {
        // this.props.pageChange('flashcards'); //uncomment to skip authenication

        let email = this.state.auth.currentUser ? this.state.auth.currentUser.email : '';

        if(this.state.auth.currentUser){
            if (this.state.auth.currentUser.emailVerified) {
                this.props.pageChange('flashcards');
            } else {
                this.state.auth.currentUser.reload();
            }
        }

        let display = this.state.verificationSent?
            { display: 'initial' } :
            { display: 'none' };


        return (
            <div>
                <Helmet>
                    <title>Aish Gemara Card</title>
                </Helmet>
                <h1 id='title'>Aish Gemara Card</h1>
                    <div className="verifyForm">
                        <h2 id='verifyPrompt'>
                            Account created! Verify your email to continue...
                        </h2>
                        <div id='verificationSent'>
                            <h3 style={display}>
                                A verification link was sent to: {email}
                            </h3>
                        </div>
                        <div id='verifyButtons'>
                            <button id="sendVerificationLink"
                            onClick={() => this.sendVerificationLink()}>
                                VERIFY EMAIL
                            </button>
                            <button id="signOut" onClick={() => this.handleLogOut()}>
                                SIGN OUT
                            </button>
                        </div>
                </div>
            </div>
        );
    }
}

class NavBar extends React.Component {
	render() {
		return (
			<div className="navbar">
            <Helmet>
                <title>Aish Gemara Card</title>
            </Helmet>
				<button
				onClick={() => this.props.onClick('flashcards')}
				>Flashcards
				</button>
				<button
				onClick={() => this.props.onClick('quiz')}
				>Quiz
				</button>
				<button
				onClick={() => this.props.onClick('dictionary')}
				>Dictionary
				</button>
                <button
				onClick={() => this.props.onClick('account')}
				>My Account
				</button>
                <button
				onClick={() => this.props.onClick('progress')}
				>My Progress
				</button>
			</div>
		);
	}
}

class Quiz extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			quizResults: [],
            quizLength: 0,
            phraseQuiz: false,
			finishedQuiz: false,
			quizStarted: false,
            userDataUpdated: false,
            scores: this.props.scores,
		};
	}

	generateAnswerIndices() {
		let answer = Math.floor(Math.random() * 4);

		let incorrect = Math.floor(Math.random() * 4);

		if(incorrect === answer) {
			if (answer < 3) {
				incorrect++;
			}
			else {
				incorrect--;
			}
		}

		let incorrect1 = Math.floor(Math.random() * 4);

		do {
			incorrect1 = Math.floor(Math.random() * 4);
		} while (incorrect1 === incorrect || incorrect1 === answer);

		let incorrect2 = Math.floor(Math.random() * 4);

		do {
			incorrect2 = Math.floor(Math.random() * 4);
		} while (incorrect2 === incorrect || incorrect2 === incorrect1 || incorrect2 === answer);

		return [answer, incorrect, incorrect1, incorrect2];
	}

	newWord(array) {
		let options = [];
		const quizAnswerIndices = this.generateAnswerIndices();
		const answerIndex = quizAnswerIndices[0];
		const incorrectAnswer0 = quizAnswerIndices[1];
		const incorrectAnswer1 = quizAnswerIndices[2];
		const incorrectAnswer2 = quizAnswerIndices[3];
		const quizArrayIndex = Math.floor(Math.random()*(array.length-1));
		const vocabWord = array[quizArrayIndex][0];
		options[answerIndex] = array[quizArrayIndex][1];
		options[incorrectAnswer0] = array[quizArrayIndex][2];
		options[incorrectAnswer1] = array[quizArrayIndex][3];
		options[incorrectAnswer2] = array[quizArrayIndex][4];

		return [answerIndex, incorrectAnswer0,
			incorrectAnswer1, incorrectAnswer2, options, quizArrayIndex, vocabWord];
	}

    requestThenSetUserData(localScores) {
        const userId = auth.currentUser.uid;
        const dbRef = ref(getDatabase());

        get(child(dbRef, `scores/${userId}`)).then((snapshot) => {
            if(snapshot.exists()) {
                const userScores = [...snapshot.val().scores];
                let i = 0;
                while(userScores[i] != null && localScores[i] < userScores[i]) {
                    localScores[i] = userScores[i];
                    i++;
                }
            }
        }).then(() => {
            set(ref(db, 'scores/' + userId), {
                scores: localScores,
            });

            this.props.updateUserInfo(localScores);
        }).catch((error) => {
            console.error(error);
        });
    }

	handleClick(optionChosen) {
        let result;
        let answerIndex;
        let incorrectAnswer0;
        let incorrectAnswer1;
        let incorrectAnswer2;
        let options;
        let quizArrayIndex;
        let vocabWord;
		let finishedQuiz = false;
        let scores = [...this.props.scores];

		if(this.state.totalAttempted === this.state.quizLength-1) {
			finishedQuiz = true;
		}

		let newTotalCorrect = this.state.totalCorrect.valueOf();
		let gotQuestionRight = 'Incorrect';

		if(optionChosen === this.state.answerIndex){
			newTotalCorrect++;
			gotQuestionRight = 'Correct!';
		}

		var color = (gotQuestionRight === 'Incorrect') ?
			'#F1948A' : '#54FF78'

		var quizResults = [...this.state.quizResults];
		var answer = this.state.options[this.state.answerIndex];

		quizResults.push([this.state.vocabWord, answer, gotQuestionRight, color]);


		const newArray = [...this.state.quizArray];
		newArray.splice(this.state.quizArrayIndex, 1); //this should be spliced before a new word is chosen

        if(!finishedQuiz) {
            result = this.newWord(newArray);
    		answerIndex = result[0];
    		incorrectAnswer0 = result[1];
    		incorrectAnswer1 = result[2];
    		incorrectAnswer2 = result[3];
    		options = result[4];
    		quizArrayIndex = result[5];
    		vocabWord = result[6];
        } else {
            const userId = auth.currentUser.uid;
            const randomEasyAttemptInd = 5;
            const randomEasyPassedInd = 6;
            const randomMedAttemptInd = 12;
            const randomMedPassedInd = 13;
            const randomHardAttemptInd = 18;
            const randomHardPassedInd = 19;
            let needUpdate = false;

            const quizPassed = this.state.totalCorrect > 14; //only useful for random quizzes

            if(this.state.quizChoiceInd === randomEasyAttemptInd){
                needUpdate = true;
                scores[randomEasyAttemptInd]++;
                if(quizPassed)
                    scores[randomEasyPassedInd]++;
            } else if (this.state.quizChoiceInd === randomMedAttemptInd) {
                needUpdate = true;
                scores[randomMedAttemptInd]++;
                if(quizPassed)
                    scores[randomMedPassedInd]++;
            } else if (this.state.quizChoiceInd === randomHardAttemptInd) {
                needUpdate = true;
                scores[randomHardAttemptInd]++;
                if(quizPassed)
                    scores[randomHardPassedInd]++;
            } else if(newTotalCorrect > scores[this.state.quizChoiceInd]) {
                //update score on database
                needUpdate = true;
                scores[this.state.quizChoiceInd] = newTotalCorrect;
            }

            if(needUpdate) {
                if(this.props.userDataRecieved) {
                    set(ref(db, 'scores/' + userId), {
                        scores: scores,
                    }).then(() => {
                        this.props.updateUserInfo(scores);
                    });
                } else {
                    this.requestThenSetUserData(scores);
                }
            }
        }

		this.setState({
			quizResults: quizResults,
			vocabWord: vocabWord,
			options: options,
            scores: scores,
			answerIndex: answerIndex,
			incorrectAnswer0: incorrectAnswer0,
			incorrectAnswer1: incorrectAnswer1,
			incorrectAnswer2: incorrectAnswer2,
			quizArrayIndex: quizArrayIndex,
			totalCorrect: newTotalCorrect,
			totalAttempted: this.state.totalAttempted + 1,
			quizArray: newArray,
			finishedQuiz: finishedQuiz,
		});
	}

	toggleFont() {
		this.setState({
			rashiScript: !this.state.rashiScript,
		});
	}

	selectQuiz(quizName) {
		var array;
        let quizLength;
        let quizChoiceInd = -1;
        let phraseQuiz = false;

        switch(quizName) {
            case 'easy1':
                array = easy1;
                quizChoiceInd = 0;
                break;
            case 'easy2':
                array = easy2;
                quizChoiceInd = 1;
                break;
            case 'easy3':
                array = easy3;
                quizChoiceInd = 2;
                break;
            case 'easy4':
                array = easy4;
                quizChoiceInd = 3;
                break;
            case 'easy5':
                array = easy5;
                quizChoiceInd = 4;
                break;
            case 'easy':
                array = easyHardCodedQuizArray;
                quizLength = 20;
                quizChoiceInd = 5;
                break;
            case 'med1':
                array = med1;
                quizChoiceInd = 7;
                break;
            case 'med2':
                array = med2;
                quizChoiceInd = 8;
                break;
            case 'med3':
                array = med3;
                quizChoiceInd = 9;
                break;
            case 'med4':
                array = med4;
                quizChoiceInd = 10;
                break;
            case 'med5':
                array = med5;
                quizChoiceInd = 11;
                break;
            case 'medium':
                array = mediumHardCodedQuizArray;
                quizLength = 20;
                quizChoiceInd = 12;
                break;
            case 'hard1':
                array = hard1;
                quizChoiceInd = 14;
                break;
            case 'hard2':
                array = hard2;
                quizChoiceInd = 15;
                break;
            case 'hard3':
                array = hard3;
                quizChoiceInd = 16;
                break;
            case 'hard4':
                array = hard4;
                quizChoiceInd = 17;
                break;
            case 'hard':
                array = hardHardCodedQuizArray;
                quizLength = 20;
                quizChoiceInd = 18;
                break;
            case 'fp1':
                array = fp1;
                phraseQuiz = true;
                quizChoiceInd = 20;
                break;
            case 'fp2':
                array = fp2;
                phraseQuiz = true;
                quizChoiceInd = 21;
                break;
            case 'fp3':
                array = fp3;
                phraseQuiz = true;
                quizChoiceInd = 22;
                break;
            case 'fp4':
                array = fp4;
                phraseQuiz = true;
                quizChoiceInd = 23;
                break;
            case 'rt1':
                array = rt1;
                quizChoiceInd = 24;
                break;
            case 'rt2':
                array = rt2;
                quizChoiceInd = 25;
                break;
            case 'rt3':
                array = rt3;
                quizChoiceInd = 26;
                break;
            case 'rt4':
                array = rt4;
                quizChoiceInd = 27;
                break;
            case 'prefixes':
                array = prefixes;
                quizChoiceInd = 28;
                break;
            case 'gematria':
                array = gematria;
                quizChoiceInd = 29;
                break;
            case 'possessivePronouns':
                array = possessivePronouns;
                quizChoiceInd = 30;
                break;
        }

		const results = this.newWord(array);
		const answerIndex = results[0];
		const incorrectAnswer0 = results[1];
		const incorrectAnswer1 = results[2];
		const incorrectAnswer2 = results[3];
		const options = results[4];
		const quizArrayIndex = results[5];
		const vocabWord = results[6];

        if(quizLength !== 20) {
            quizLength = array.length;
        }

		this.setState({
			vocabWord: vocabWord,
			answerIndex: answerIndex,
			incorrectAnswer0: incorrectAnswer0,
			incorrectAnswer1: incorrectAnswer1,
			incorrectAnswer2: incorrectAnswer2,
			options: options,
			quizArrayIndex: quizArrayIndex,
			totalCorrect: 0,
			totalAttempted: 0,
			rashiScript: false,
			finishedQuiz: false,
			quizStarted: true,
			quizArray: [...array],
			quizResults: [],
            quizChoiceInd: quizChoiceInd,
            quizLength: quizLength,
            phraseQuiz: phraseQuiz,
		})
	}

    displayQuizzes() {
        this.setState({
            quizResults: [],
            quizLength: 0,
			finishedQuiz: false,
			quizStarted: false,
            userDataUpdated: false,
            scores: this.props.scores,
            totalCorrect: 0,
            totalAttempted: 0,
        })
    }

	render() {
		const font = this.state.rashiScript ? '\'Noto Rashi Hebrew\', serif' :
				'\'Noto Serif Hebrew\', serif';
        const quizClass = this.state.phraseQuiz ? 'phraseQuiz' : '';
        const rashiButtonVisibility = this.state.phraseQuiz ? 'none' : '';
        const isQuizSelector = true;

		const vocabWord = this.state.vocabWord;
		const options = this.state.options;
		const totalCorrect = this.state.totalCorrect;
		const totalAttempted = this.state.totalAttempted;

		if(!this.state.quizStarted) {
            return (
                <>
                <div className="quizSelectionBackground" />
                <h1 className='accountTitle'>Select a Quiz</h1>
                <div className="dropWrapper">
                    <DropdownMenu isQuizSelector={isQuizSelector} selectQuiz={(quizName) => this.selectQuiz(quizName)} config={[
                    {
                      "title": "Select a Quiz ▾",
                      "submenu": [
                        {
                          "title": "Easy Vocab ▸",
                          "submenu": [
                            {
                              "title": "#1\nא-ב\n(19)",
                              "quizName": "easy1",
                              "submenu": null
                            },
                            {
                              "title": "#2\nג-ה\n(26)",
                              "quizName": "easy2",
                              "submenu": null
                            },
                            {
                              "title": "#3\nו-ל\n(17)",
                              "quizName": "easy3",
                              "submenu": null
                            },
                            {
                              "title": "#4\nמ-צ\n(24)",
                              "quizName": "easy4",
                              "submenu": null
                            },
                            {
                              "title": "#5\nק-ת\n(20)",
                              "quizName": "easy5",
                              "submenu": null
                            },
                            {
                              "title": "Random\nא-ת\n(20)",
                              "quizName": "easy",
                              "submenu": null
                            }
                          ]
                        },
                        {
                          "title": "Medium Vocab ▸",
                          "submenu": [
                              {
                                "title": "#1\nא-ד\n(19)",
                                "quizName": "med1",
                                "submenu": null
                              },
                              {
                                "title": "#2\nה-כ\n(23)",
                                "quizName": "med2",
                                "submenu": null
                              },
                              {
                                "title": "#3\nל-מ\n(29)",
                                "quizName": "med3",
                                "submenu": null
                              },
                              {
                                "title": "#4\nנ-ש\n(17)",
                                "quizName": "med4",
                                "submenu": null
                              },
                              {
                                "title": "#5\nת\n(15)",
                                "quizName": "med5",
                                "submenu": null
                              },
                              {
                                "title": "Random\nא-ת\n(20)",
                                "quizName": "medium",
                                "submenu": null
                              }
                          ]
                        },
                        {
                        "title": "Hard Vocab ▸",
                        "submenu": [
                            {
                              "title": "#1\nא\n(20)",
                              "quizName": "hard1",
                              "submenu": null
                            },
                            {
                              "title": "#2\nב-י\n(19)",
                              "quizName": "hard2",
                              "submenu": null
                            },
                            {
                              "title": "#3\nכ-נ\n(23)",
                              "quizName": "hard3",
                              "submenu": null
                            },
                            {
                              "title": "#4\nס-ת\n(20)",
                              "quizName": "hard4",
                              "submenu": null
                            },
                            {
                              "title": "Random\nא-ת\n(20)",
                              "quizName": "hard",
                              "submenu": null
                            }
                        ]
                        },
                        {
                        "title": "Function Phrases ▸",
                        "submenu": [
                            {
                              "title": "#1\nא-ג\n(13)",
                              "quizName": "fp1",
                              "submenu": null
                            },
                            {
                              "title": "#2\nד-ל\n(15)",
                              "quizName": "fp2",
                              "submenu": null
                            },
                            {
                              "title": "#3\nמ-צ\n(12)",
                              "quizName": "fp3",
                              "submenu": null
                            },
                            {
                              "title": "#4\nק-ת\n(12)",
                              "quizName": "fp4",
                              "submenu": null
                            },
                        ]
                        },
                        {
                        "title": "Roshei Teivos ▸",
                        "submenu": [
                            {
                              "title": "#1\nא\n(19)",
                              "quizName": "rt1",
                              "submenu": null
                            },
                            {
                              "title": "#2\nב-י\n(19)",
                              "quizName": "rt2",
                              "submenu": null
                            },
                            {
                              "title": "#3\nכ-ס\n(13)",
                              "quizName": "rt3",
                              "submenu": null
                            },
                            {
                              "title": "#4\nע-ת\n(18)",
                              "quizName": "rt4",
                              "submenu": null
                            },
                        ]
                        },
                        {
                        "title": "Prefixes ▸",
                        "submenu": [
                            {
                              "title": "#1\nא-ת\n(13)",
                              "quizName": "prefixes",
                              "submenu": null
                            },
                        ]
                        },
                        {
                        "title": "Gematria ▸",
                        "submenu": [
                            {
                              "title": "#1\nא-ת\n(27)",
                              "quizName": "gematria",
                              "submenu": null
                          },
                        ]
                        },
                        {
                        "title": "Possessive Pronouns ▸",
                        "submenu": [
                            {
                              "title": "#1\nא-ת\n(7)",
                              "quizName": "possessivePronouns",
                              "submenu": null
                            },
                        ]
                        }
                      ]
                    }
                ]} />
            </div>
            </>
          );
		} else if(this.state.finishedQuiz) {
			const results = this.state.quizResults;

			const quizResults = results.map((data, index) => {
				const backgroundColor = data[3];
				const style = {
					backgroundColor: backgroundColor
				};

				return(
					<tr style={style}>
						<td>{data[0]}</td>
						<td>{data[1]}</td>
						<td>{data[2]}</td>
					</tr>
				);
			});

			return (
				<div className="endOfQuizPrompt">
					<button id='returnToQuizzes'
                    onClick={() => this.displayQuizzes()}>Take another quiz</button>
					<div className="quizResultsWrapper">
						<h1>Quiz Results:  {totalCorrect}/{this.state.quizLength}
						</h1>
						<table>
							<thead>
								<tr>
									<th>Word</th>
									<th>Translation</th>
									<th>Correct/Incorrect</th>
								</tr>
							</thead>
							<tbody>
								{quizResults}
							</tbody>
						</table>
						<br /><br />
					</div>
				</div>
			);
		}

		return (
			<div className="quiz">
				<div className="question">
					<h1 style={{fontFamily: font}}
                    className={quizClass}>
						{vocabWord}
					</h1>
				</div>
				<button style={{display: rashiButtonVisibility}} onClick={() => this.toggleFont()}
				className='fontToggle'>
					Toggle Rashi Script
				</button>

				<div className="progress">
					Progress: {totalAttempted}/{this.state.quizLength}
				</div>
				<div className="answersContainer">
                    <div className='answer'>
                        <p className="answerText">{options[0]}</p>
                        <button
                        onClick={() => this.handleClick(0)}
                        className="answerButton">
                        </button>
                    </div>
                    <div className='answer'>
                        <p className="answerText">{options[1]}</p>
                        <button
                        onClick={() => this.handleClick(1)}
                        className="answerButton">
                        </button>
                    </div>
                    <div className='answer'>
                        <p className="answerText">{options[2]}</p>
                        <button
                        onClick={() => this.handleClick(2)}
                        className="answerButton">
                        </button>
                    </div>
                    <div className='answer'>
                        <p className="answerText">{options[3]}</p>
                        <button
                        onClick={() => this.handleClick(3)}
                        className="answerButton">
                        </button>
                    </div>
				</div>
			</div>
		);
	}
}

class UI extends React.Component {
	constructor(props) {
		super(props);

        let page = 'signUpForm';
        if(this.props.loggedIn) {
            page = 'flashcards';
        }

		this.state = {
            auth: getAuth(),
			page: page,
            userDataRequested: false,
            userDataRecieved: false,
            scores: new Array(31).fill(0),
            firstName: 'loading...',
            lastName: 'loading...',
            email: 'loading...',
		}
	}

	pageChange(page) {
        this.setState ({
			page: page,
		});
	}

    updateUserInfo(array) {
        this.setState ({
            scores: [...array],
            userDataRecieved: true,
        })
    }

    signOut() {
        this.setState({
            page: 'signUpForm',
            userDataRequested: false,
            scores: new Array(31).fill(0),
            firstName: '',
            lastName: '',
            email: '',
        })
    }

    updateEmail(email) {
        this.setState({
            email: email,
        })
    }

	render() {
		if(this.state.page === 'quiz'){
			return (
				<div>
					<NavBar
					onClick={(page) => this.pageChange(page)}
					/>
					<Quiz
                    scores={this.state.scores}
                    userDataRecieved={this.state.userDataRecieved}
                    updateUserInfo={(array) => this.updateUserInfo(array)} />
                    <img className="aishLogo" src={aishLogo} alt="Aish HaTorah" />
				</div>
			);
		}
		else if (this.state.page === 'dictionary') {
			return (
				<div>
					<NavBar
					onClick={(page) => this.pageChange(page)}
					/>
					<div className="dictionaryList">
						{
							vocabArray.map((pair, index) =>
								<p>{pair[0]}.......... {pair[1]}</p>
							)
						}
					</div>
                    <img className="aishLogo" src={aishLogo} alt="Aish HaTorah" />
				</div>
			);
		}
        else if (this.state.page === 'account') {
			return (
				<div>
					<NavBar
					onClick={(page) => this.pageChange(page)}
					/>
					<Account pageChange={(page) => this.pageChange(page)}
                    signOut={() => this.signOut()}
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    email={this.state.email}/>
                    <img className="aishLogo" src={aishLogo} alt="Aish HaTorah" />
				</div>
			);
		}
        else if (this.state.page === 'progress') {
			return (
				<div>
					<NavBar
					onClick={(page) => this.pageChange(page)}
					/>
					<Progress pageChange={(page) => this.pageChange(page)}
                    scores={this.state.scores} />
                    <img className="aishLogo" src={aishLogo} alt="Aish HaTorah" />
				</div>
			);
		}
        else if (this.state.page === 'signUpForm') {
			return (
				<div>
					<SignUpForm pageChange={(page) => this.pageChange(page)}
                    updateEmail={(email) => this.updateEmail(email)}
                    />
                    <img className="aishLogo" src={aishLogo} alt="Aish HaTorah" />
				</div>
			);
		}
        else if (this.state.page === 'verify') {
            return (
                <>
                    <Verify
                    signOut={() => this.signOut()}
                    pageChange={(page) => this.pageChange(page)}
                    email={this.state.email}
                    />
                    <img className="aishLogo" src={aishLogo} alt="Aish HaTorah" />
                </>
            )
        }
		else {
            if (!this.state.userDataRequested) {
                const userId = auth.currentUser.uid;
                const dbRef = ref(getDatabase());
                let firstName;
                let lastName;
                let email;
                let scores;

                //get user data
                get(child(dbRef, `users/${userId}`)).then((snapshot) => {
                    if (snapshot.exists()) {
                        firstName = snapshot.val().firstName;
                        lastName = snapshot.val().lastName;
                        email = snapshot.val().email;
                    } else {
                        console.log("No data available");
                    }
                }).then(() => {
                    //get scores
                    get(child(dbRef, `scores/${userId}`)).then((snapshot) => {
                        if(snapshot.exists()) {

                            scores = [...snapshot.val().scores];

                            while(scores.length < 31) {
                                scores.push(0);
                            }
                            get(child(dbRef, `users/${userId}`)).then((snapshot) => {
                                if (snapshot.exists()) {
                                    if(scores[5] < snapshot.val().easyQuizzesAttempted) {
                                        scores[5] = snapshot.val().easyQuizzesAttempted;
                                        scores[6] = snapshot.val().easyQuizzesPassed;
                                    }
                                    if(scores[12] < snapshot.val().mediumQuizzesAttempted) {
                                        scores[12] = snapshot.val().mediumQuizzesAttempted;
                                        scores[13] = snapshot.val().mediumQuizzesPassed;
                                    }
                                    if(scores[18] < snapshot.val().hardQuizzesAttempted) {
                                        scores[18] = snapshot.val().hardQuizzesAttempted;
                                        scores[19] = snapshot.val().hardQuizzesPassed;
                                    }
                                }
                            }).then(() => {
                                set(ref(db, 'scores/' + userId), {
                                    scores: scores,
                                });
                            });
                        } else {
                            get(child(dbRef, `users/${userId}`)).then((snapshot) => {
                                scores = [...this.state.scores];

                                if (snapshot.exists()) {
                                    scores[5] = snapshot.val().easyQuizzesAttempted;
                                    scores[6] = snapshot.val().easyQuizzesPassed;
                                    scores[12] = snapshot.val().mediumQuizzesAttempted;
                                    scores[13] = snapshot.val().mediumQuizzesPassed;
                                    scores[18] = snapshot.val().hardQuizzesAttempted;
                                    scores[19] = snapshot.val().hardQuizzesPassed;
                                }

                                return scores;
                            }).then((scores) => {
                                set(ref(db, 'scores/' + userId), {
                                    scores: scores,
                                });
                            });
                        }
                    }).then(() => {
                        this.setState({
                            userDataRequested: true,
                            scores: scores,
                            userDataRecieved: true,
                            firstName: firstName,
                            lastName: lastName,
                            email: email,
                        });

                    }).catch((error) => {
                        console.error(error);
                    });
                })
            }

			return(
				<div>
					<NavBar
					onClick={(page) => this.pageChange(page)}
					/>
					<Card />
                    <img className="aishLogo" src={aishLogo} alt="Aish HaTorah" />
				</div>
			);
		}
	}
}

class Card extends React.Component {
	constructor(props) {
  		super(props);
  		this.state = {
  				vocabIndex: 0,
				showEnglish: false,
				rashiScript: false,
				deck: easy1_Cards,
  		};
    }

	handleFlip() {
		this.setState({
			showEnglish: !this.state.showEnglish,
		});
	}

	handleNext() {
		let index;
		if(this.state.vocabIndex === this.state.deck.length - 1){
			index = 0;
		}
		else {
			index = this.state.vocabIndex + 1;
		}
		this.setState({
			vocabIndex: index,
		});
	}

	handlePrev() {
		let index;
		if(this.state.vocabIndex === 0){
			index = this.state.deck.length - 1;
		}
		else {
			index = this.state.vocabIndex - 1;
		}
		this.setState({
			vocabIndex: index,
		});
	}

	toggleFont() {
		this.setState({
			rashiScript: !this.state.rashiScript,
		});
	}

    selectDeck(deckName) {
        var deck;
        let needSmallText = false;

        switch(deckName) {
            case 'easy1_Cards':
                deck = easy1_Cards;
                break;
            case 'easy2_Cards':
                deck = easy2_Cards;
                break;
            case 'easy3_Cards':
                deck = easy3_Cards;
                break;
            case 'easy4_Cards':
                deck = easy4_Cards;
                break;
            case 'easy5_Cards':
                deck = easy5_Cards;
                break;
            case 'med1_Cards':
                deck = med1_Cards;
                break;
            case 'med2_Cards':
                deck = med2_Cards;
                break;
            case 'med3_Cards':
                deck = med3_Cards;
                break;
            case 'med4_Cards':
                deck = med4_Cards;
                break;
            case 'med5_Cards':
                deck = med5_Cards;
                break;
            case 'hard1_Cards':
                deck = hard1_Cards;
                break;
            case 'hard2_Cards':
                deck = hard2_Cards;
                break;
            case 'hard3_Cards':
                deck = hard3_Cards;
                break;
            case 'hard4_Cards':
                deck = hard4_Cards;
                break;
            case 'fp1_Cards':
                deck = fp1_Cards;
                needSmallText = true;
                break;
            case 'fp2_Cards':
                deck = fp2_Cards;
                needSmallText = true;
                break;
            case 'fp3_Cards':
                deck = fp3_Cards;
                needSmallText = true;
                break;
            case 'fp4_Cards':
                deck = fp4_Cards;
                needSmallText = true;
                break;
            case 'rt1_Cards':
                deck = rt1_Cards;
                needSmallText = true;
                break;
            case 'rt2_Cards':
                deck = rt2_Cards;
                needSmallText = true;
                break;
            case 'rt3_Cards':
                deck = rt3_Cards;
                needSmallText = true;
                break;
            case 'rt4_Cards':
                deck = rt4_Cards;
                needSmallText = true;
                break;
            case 'prefixes_Cards':
                deck = prefixes_Cards;
                break;
            case 'gematria_Cards':
                deck = gematria_Cards;
                break;
            case 'possessivePronouns_Cards':
                deck = possessivePronouns_Cards;
                break;

            default:
                deck = easy1_Cards;
                break;
        }

    	this.setState({
    		deck: deck,
            vocabIndex: 0,
            needSmallText: needSmallText,
    	});
    }

	render() {
		let deck = this.state.deck;
        let isQuizSelector = false;

		let cardText = this.state.showEnglish ?
			deck[this.state.vocabIndex][1] :
			deck[this.state.vocabIndex][0];
		let direction = this.state.showEnglish ?
			"ltr" : "rtl";
		let font = this.state.rashiScript ? '\'Noto Rashi Hebrew\', serif' :
				'\'Noto Serif Hebrew\', serif';

        if (this.state.showEnglish) {
            font = '\'Zen Kurenaido\', sans-serif';
        }

        let fontSize = '10vmin';

        if(this.state.needSmallText && this.state.showEnglish) {
            fontSize = '5vmin';
        }

		return (
            <>
            <div className='cardBackground' />
			<div className="flashcardsWrapper">
                <div className="deckWrapper">
                </div>
				<button className="fontToggle"
				onClick={() => this.toggleFont()}>
				Toggle Rashi Script
				</button>
				<div className="card">
					<div className="textSection">
						<h1 style={
							{fontFamily: font,
							direction: direction,
                            fontSize: fontSize}}>
							{cardText}
						</h1>
					</div>
				</div>
                <div className="cardButtons">
                	<button
                	onClick={() => this.handlePrev()}>
                	Prev
                	</button>
                	<button
                	onClick={() => this.handleFlip()}>
                	Flip
                	</button>
                	<button
                	onClick={() => this.handleNext()}>
                	Next
                	</button>
                </div>
                <DropdownMenu isQuizSelector={isQuizSelector} selectDeck={(deckName) => this.selectDeck(deckName)} config={[
                {
                  "title": "Select a Deck ▾",
                  "submenu": [
                	{
                	  "title": "Easy Vocab ▸",
                	  "submenu": [
                		{
                		  "title": "#1\nא-ב\n(19)",
                		  "deckName": "easy1_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#2\nג-ה\n(26)",
                		  "deckName": "easy2_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#3\nו-ל\n(17)",
                		  "deckName": "easy3_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#4\nמ-צ\n(24)",
                		  "deckName": "easy4_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#5\nק-ת\n(20)",
                		  "deckName": "easy5_Cards",
                		  "submenu": null
                		},
                	  ]
                	},
                	{
                	  "title": "Medium Vocab ▸",
                	  "submenu": [
                		  {
                			"title": "#1\nא-ד\n(19)",
                			"deckName": "med1_Cards",
                			"submenu": null
                		  },
                		  {
                			"title": "#2\nה-כ\n(23)",
                			"deckName": "med2_Cards",
                			"submenu": null
                		  },
                		  {
                			"title": "#3\nל-מ\n(29)",
                			"deckName": "med3_Cards",
                			"submenu": null
                		  },
                		  {
                			"title": "#4\nנ-ש\n(17)",
                			"deckName": "med4_Cards",
                			"submenu": null
                		  },
                		  {
                			"title": "#5\nת\n(15)",
                			"deckName": "med5_Cards",
                			"submenu": null
                		  },
                	  ]
                	},
                	{
                	"title": "Hard Vocab ▸",
                	"submenu": [
                		{
                		  "title": "#1\nא\n(20)",
                		  "deckName": "hard1_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#2\nב-י\n(19)",
                		  "deckName": "hard2_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#3\nכ-נ\n(23)",
                		  "deckName": "hard3_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#4\nס-ת\n(20)",
                		  "deckName": "hard4_Cards",
                		  "submenu": null
                		},
                	]
                	},
                	{
                	"title": "Function Phrases ▸",
                	"submenu": [
                		{
                		  "title": "#1\nא-ג\n(13)",
                		  "deckName": "fp1_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#2\nד-ל\n(15)",
                		  "deckName": "fp2_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#3\nמ-צ\n(12)",
                		  "deckName": "fp3_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#4\nק-ת\n(12)",
                		  "deckName": "fp4_Cards",
                		  "submenu": null
                		},
                	]
                	},
                	{
                	"title": "Roshei Teivos ▸",
                	"submenu": [
                		{
                		  "title": "#1\nא\n(19)",
                		  "deckName": "rt1_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#2\nב-י\n(19)",
                		  "deckName": "rt2_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#3\nכ-ס\n(13)",
                		  "deckName": "rt3_Cards",
                		  "submenu": null
                		},
                		{
                		  "title": "#4\nע-ת\n(18)",
                		  "deckName": "rt4_Cards",
                		  "submenu": null
                		},
                	]
                	},
                	{
                	"title": "Prefixes ▸",
                	"submenu": [
                		{
                		  "title": "#1\nא-ת\n(13)",
                		  "deckName": "prefixes_Cards",
                		  "submenu": null
                		},
                	]
                	},
                	{
                	"title": "Gematria ▸",
                	"submenu": [
                		{
                		  "title": "#1\nא-ת\n(27)",
                		  "deckName": "gematria_Cards",
                		  "submenu": null
                		}
                	]
                	},
                	{
                	"title": "Possessive Pronouns ▸",
                	"submenu": [
                		{
                		  "title": "#1\nא-ת\n(7)",
                		  "deckName": "possessivePronouns_Cards",
                		  "submenu": null
                		},
                	]
                	}
                  ]
                }
                ]}/>
			</div>
            </>
		);
	}
}

class Account extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            email: this.props.email,
        }
    }

    handleLogOut() {
        signOut(auth).then(() => {
          // Sign-out successful.
          this.props.signOut();
        }).catch((error) => {
          // An error happened.
        });
    }

    render(props) {
        return(
            <>
            <div className='accountBackground' />
            <h1 className='accountTitle'>My Account</h1>
            <div className='myAccountWrapper'>
                <h3>First Name: {this.props.firstName}</h3>
                <h3>Last Name: {this.props.lastName}</h3>
                <h3>Email: {this.props.email}</h3>
                <br />
                To learn more Gemara skills, email us at <a href="mailto:yeshiva@aish.edu">yeshiva@aish.edu</a>
                <br /><br /><br />
                <button onClick={() => this.handleLogOut()} className='logout'>Log out</button>
            </div>
            </>
        );
    }
}

class Progress extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            scores: this.props.scores,
        }
    }

    render(props) {
        const randomEasyAttemptInd = 5;
        const randomEasyPassedInd = 6;
        const randomMedAttemptInd = 12;
        const randomMedPassedInd = 13;
        const randomHardAttemptInd = 18;
        const randomHardPassedInd = 19;

        var easyWidth = Math.floor((this.state.scores[randomEasyPassedInd] / this.state.scores[randomEasyAttemptInd])*100);
        if(isNaN(easyWidth)){
            easyWidth = 0;
        }

        let mediumWidth = Math.floor((this.state.scores[randomMedPassedInd] / this.state.scores[randomMedAttemptInd])*100);
        if(isNaN(mediumWidth)){
            mediumWidth = 0;
        }

        let hardWidth = Math.floor((this.state.scores[randomHardPassedInd] / this.state.scores[randomHardAttemptInd])*100);
        if(isNaN(hardWidth)){
            hardWidth = 0;
        }

        let totalQuizzesPassed = (this.state.scores[randomEasyPassedInd] + this.state.scores[randomMedPassedInd] + this.state.scores[randomHardPassedInd]);

        let totalQuizzesAttempted = (this.state.scores[randomEasyAttemptInd] + this.state.scores[randomMedAttemptInd] + this.state.scores[randomHardAttemptInd]);

        let totalWidth = Math.floor(totalQuizzesPassed*100 / totalQuizzesAttempted);

        if(isNaN(totalWidth)){
            totalWidth = 0;
        }

        const scores = this.state.scores;

        return(
            <>
            <div className='progressBackground' />
            <h1 className='accountTitle'>My Progress</h1>
            <div className='myAccountWrapper'>
                <h1>Your Highest Scores</h1>
                <table id='highscores'>
                    <thead>
                        <tr>
                            <th className='scoreBoardTh'>Quiz #</th>
                            <th className='scoreBoardTh'>Easy</th>
                            <th className='scoreBoardTh'>Medium</th>
                            <th className='scoreBoardTh'>Hard</th>
                            <th className='scoreBoardTh'>Function Phrases</th>
                            <th className='scoreBoardTh'>Roshei Teivos</th>
                            <th className='scoreBoardTh'>Prefixes</th>
                            <th className='scoreBoardTh'>Gematria</th>
                            <th className='scoreBoardTh'>Possesive Pronouns</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="highscore">1</td>
                            <td className="highscore">א-ב<br /><br />{scores[0]}/19</td>
                            <td className="highscore">א-ד<br /><br />{scores[7]}/19</td>
                            <td className="highscore">א<br /><br />{scores[14]}/20</td>
                            <td className="highscore">א-ג<br /><br />{scores[20]}/13</td>
                            <td className="highscore">א<br /><br />{scores[24]}/19</td>
                            <td className="highscore">{scores[28]}/13</td>
                            <td className="highscore">{scores[29]}/27</td>
                            <td className="highscore">{scores[30]}/7</td>
                        </tr>
                        <tr>
                            <td className="highscore">2</td>
                            <td className="highscore">ג-ה<br /><br />{scores[1]}/26</td>
                            <td className="highscore">ה-כ<br /><br />{scores[8]}/23</td>
                            <td className="highscore">ב-י<br /><br />{scores[15]}/19</td>
                            <td className="highscore">ד-ל<br /><br />{scores[21]}/15</td>
                            <td className="highscore">ב-י<br /><br />{scores[25]}/19</td>
                        </tr>
                        <tr>
                            <td className="highscore">3</td>
                            <td className="highscore">ו-ל<br /><br />{scores[2]}/17</td>
                            <td className="highscore">ל-מ<br /><br />{scores[9]}/29</td>
                            <td className="highscore">כ-נ<br /><br />{scores[16]}/23</td>
                            <td className="highscore">מ-צ<br /><br />{scores[22]}/12</td>
                            <td className="highscore">כ-ס<br /><br />{scores[26]}/13</td>
                        </tr>
                        <tr>
                            <td className="highscore">4</td>
                            <td className="highscore">מ-צ<br /><br />{scores[3]}/24</td>
                            <td className="highscore">נ-ש<br /><br />{scores[10]}/17</td>
                            <td className="highscore">ס-ת<br /><br />{scores[17]}/20</td>
                            <td className="highscore">ק-ת<br /><br />{scores[23]}/12</td>
                            <td className="highscore">ע-ת<br /><br />{scores[27]}/18</td>
                        </tr>
                        <tr>
                            <td className="highscore">5</td>
                            <td className="highscore">ק-ת<br /><br />{scores[4]}/20</td>
                            <td className="highscore">ת<br /><br />{scores[11]}/15</td>
                        </tr>
                    </tbody>
                </table>
                <br /><br /><br />
                <h1>Random Quizzes</h1>
                <h2>Easy</h2>
                    <h3>Quizzes attempted: {this.state.scores[randomEasyAttemptInd]}</h3>
                    <h3>Quizzes passed: {this.state.scores[randomEasyPassedInd]}</h3>
                    <div className="progressBarContainer">
                        <div className="progressBar">
                            <div className="progressBarContents"  style={{width: easyWidth+'%'}}>
                                <p>{easyWidth}%</p>
                            </div>
                        </div>
                    </div>
                <h2>Medium</h2>
                    <h3>Quizzes attempted: {this.state.scores[randomMedAttemptInd]}</h3>
                    <h3>Quizzes passed: {this.state.scores[randomMedPassedInd]}</h3>
                    <div className="progressBarContainer">
                        <div className="progressBar">
                            <div className="progressBarContents"  style={{width: mediumWidth+'%'}}>
                                <p>{mediumWidth}%</p>
                            </div>
                        </div>
                    </div>
                <h2>Hard</h2>
                    <h3>Quizzes attempted: {this.state.scores[randomHardAttemptInd]} </h3>
                    <h3>Quizzes passed: {this.state.scores[randomHardPassedInd]}</h3>
                    <div className="progressBarContainer">
                        <div className="progressBar">
                            <div className="progressBarContents"  style={{width: hardWidth+'%'}}>
                                <p>{hardWidth}%</p>
                            </div>
                        </div>
                    </div>
                <h2>Total</h2>
                <h3>Quizzes attempted: {totalQuizzesAttempted}</h3>
                <h3>Quizzes passed: {totalQuizzesPassed}</h3>
                <div className="progressBarContainer">
                    <div className="progressBar">
                        <div className="progressBarContents"  style={{width: totalWidth+'%'}}>
                            <p>{totalWidth}%</p>
                        </div>
                    </div>
                </div>

                <br /><br /><br /><br />
            </div>
            </>
        );
    }
}

class DropdownMenu extends React.Component {

  getMenuItemTitle = (menuItem, index, depthLevel) => {
    return menuItem.title;
  };

  getMenuItem = (menuItem, depthLevel, index) => {
    let title = this.getMenuItemTitle(menuItem, index, depthLevel);

    if (menuItem.submenu && menuItem.submenu.length > 0) {
      if(this.props.isQuizSelector){
          return (
            <li>
              {title}
              <DropdownMenu isQuizSelector={this.props.isQuizSelector} selectQuiz={(quizName) => this.props.selectQuiz(quizName)} config={menuItem.submenu} submenu={true} />
            </li>
          );
      }
      else {
          return (
            <li>
              {title}
              <DropdownMenu selectDeck={(deckName) => this.props.selectDeck(deckName)} config={menuItem.submenu} submenu={true} />
            </li>
          );
      }
    } else {
        if(this.props.isQuizSelector) {
            return <li onClick={() => this.props.selectQuiz(menuItem.quizName)}>{title}</li>;
        }

        return <li onClick={() => this.props.selectDeck(menuItem.deckName)}>{title}</li>;
    }
  };

  render = () => {
    let { config } = this.props;
    let id = this.props.isQuizSelector ? "quizSelector" : "deckSelector"

    let options = [];
    config.map((item, index) => {
      options.push(this.getMenuItem(item, 0, index));
    });

    if (this.props.submenu && this.props.submenu === true)
      return <ul>{options}</ul>;

    return <ul id={id} className="dropdown-menu">{options}</ul>;
  };
}







// ============================================================
